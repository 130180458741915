import * as odataService from '@/core/common/services/odata.service';
import { add, parse, subDays, format } from 'date-fns';

export const GET_REISETERMINE = 'reisefinder.getReisetermine';
export const SET_REISETERMINE = 'reisefinder.setReisetermine';
export const SET_IS_BUSY = 'reisefinder.setIsBusy';
export const SET_IS_INITIALLY_LOADING = 'reisefinder.setIsInitiallyLoading';
export const SET_COUNT = 'reisefinder.setCount';

const state = () => ({
  reisetermine: [],
  isInitiallyLoading: true,
  isBusy: false,
  count: null,
  expand: {
    reise: {},
    pax: {},
    flugeinkauf: {},
    metadata: {
      // select: [
      //   'transferszenarioHin',
      //   'transferszenarioRueck',
      //   'kalkPax',
      //   'fruehesteAnkunft',
      //   'spaetesteAnkunft',
      //   'fruehesterAbflug',
      //   'spaetesterAbflug',
      //   'restriktionenHinRollover',
      //   'restriktionenRueckRollover',
      //   'vorverlaengerung',
      // ],
      expand: {
        ziellandZielflughaefen: {},
        ziellandAbflughaefen: {},
      },
    },
    zimmerkontingente: {
      filter: {
        isDeleted: false,
      },
      select: [
        'zimmertyp',
        'isDeleted',
        'verfuegbarkeitsStatus',
        'einheitenFrei',
        'kontingentAktuell',
        'zuschlag',
        'kontingentAktuellGeteilt',
      ],
    },
    zusatzleistungen: {
      select: [
        'typ',
        'zimmertyp',
        'einheitenFrei',
        'verfuegbarkeitsStatus',
        'beschreibung',
        'reiseterminId',
        'name',
        'kuerzel',
      ],
    },
    flugverfuegbarkeiten: {
      orderby: 'abfragedatum desc',
      select: [
        'id',
        'aufpreis',
        'status',
        'abfragedatum',
        'deutscherAbflughafenIataCode',
        'abflughafenIataCode',
        'zielflughafenIataCode',
        'zimmertyp',
        'verpflegung',
      ],
      filter: {
        abfragedatum: { ge: getDate14DaysAgo() },
      },
    },
  },
});

const getters = {};

const actions = {
  [GET_REISETERMINE](context, request) {
    context.commit(SET_IS_BUSY, true);

    return odataService
      .getReisetermin({
        filter: getOdataFilterFromAgGridRequest(context.state, request),
        ...(context?.state?.compute && { compute: context.state.compute }),
        ...(context?.state?.expand && { expand: context.state.expand }),
        ...(request.sortModel.length > 0
          ? {
              orderBy: request.sortModel.map(s => s.colId.replaceAll('.', '/') + ' ' + s.sort),
            }
          : { orderBy: 'abreisedatum asc' }),
        top: request.endRow - request.startRow,
        skip: request.startRow,
        count: true,
      })
      .then(response => {
        context.commit(SET_IS_INITIALLY_LOADING, false);
        context.commit(SET_REISETERMINE, response.data || []);
        context.commit(SET_IS_BUSY, false);
        context.commit(SET_COUNT, response.count || 0);
        return response.data;
      });
  },
};

const mutations = {
  [SET_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_IS_INITIALLY_LOADING](state, isInitiallyLoading) {
    state.isInitiallyLoading = isInitiallyLoading;
  },
  [SET_REISETERMINE](state, reisetermine) {
    state.reisetermine = reisetermine;
  },
  [SET_COUNT](state, count) {
    state.count = count;
  },
};
const getOdataFilterFromAgGridRequest = (state, request) => {
  const filterKeys = Object.keys(request.filterModel);
  let filters = [];
  const allFilterKeys = filterKeys.map(key => {
    const formattedKey = key.replaceAll('.', '/');
    if (formattedKey === 'basisPreis') {
      const maxBudget =
        request.interessenten != 1
          ? request.filterModel[key].filter
          : `${request.filterModel[key].filter} sub einzelzimmerzuschlag`;
      return `(${formattedKey} le ${maxBudget})`;
    }
    if (request.filterModel[key].filterType === 'text') {
      if (
        request.filterModel[key].filter === 'Aufenthaltsreise' ||
        request.filterModel[key].filter === 'Rundreise'
      ) {
        // custom filter for Aufenthalts-/Rundreise
        return {
          'substring(reise/reisekuerzel,2,1)': { eq: request.filterModel[key].filter.substring(0, 1) },
        };
      } else if (formattedKey === 'reiseterminkuerzel') {
        const allReiseterminkuerzel = request.filterModel[key].filter
          .split(',')
          .filter(filter => filter.length === 17);
        const allReisekuerzel = request.filterModel[key].filter
          .split(',')
          .filter(filter => filter.length === 6);
        return {
          or: {
            ...(allReiseterminkuerzel.length > 0 && { reiseterminkuerzel: { in: allReiseterminkuerzel } }),
            ...(allReisekuerzel.length > 0 && { 'reise/reisekuerzel': { in: allReisekuerzel } }),
          },
        };
      } else if (formattedKey === 'reise/titel') {
        return {
          [formattedKey]: {
            in: request.filterModel[key].filter.split(','),
          },
        };
      } else return { [formattedKey]: { startswith: request.filterModel[key].filter } };
    } else if (request.filterModel[key].filterType === 'set') {
      return { [formattedKey]: { in: request.filterModel[key].values } };
    } else if (request.filterModel[key].filterType === 'date') {
      console.log(key);
      if (key === 'reisefinderDate') {
        const { dateFrom, dateTo } = request.filterModel[key];
        const utcAbreisedatum = new Date(
          Date.UTC(+dateFrom.substring(0, 4), +dateFrom.substring(5, 7) - 1, +dateFrom.substring(8, 10))
        );
        const utcEnddatum = new Date(
          Date.UTC(+dateTo.substring(0, 4), +dateTo.substring(5, 7) - 1, +dateTo.substring(8, 10))
        );
        return {
          abreisedatum: {
            ge: utcAbreisedatum,
          },
          enddatum: {
            le: utcEnddatum,
          },
        };
      } else
        return {
          [formattedKey]: {
            ge: new Date(request.filterModel[key].dateFrom),
            le: new Date(request.filterModel[key].dateTo),
          },
        };
    }
  });

  // Zeige keine Reisen an, die abgesagt oder gelöscht wurden
  filters = [
    ...filters,
    ...allFilterKeys,
    { abgesagt: false },
    { isDeleted: false },
    {
      reiseterminSyncState: {
        in: [
          'AvailableInBlank',
          'AvailableInBlankAndSyncedHotelleistungWithRelations',
          'AvailableInBlankAndSyncedHotelleistungWithRelationsAndKontingente',
          'AvailableInBlankAndSyncedHotelleistungWithRelationsAndKontingenteAndZusatzleistungen',
        ],
      },
    },
  ];

  // Filter out Reisen, die schon die maximalen Teilnehmer erreicht haben
  if (!request.ausgebuchteTermineAnzeigen) {
    filters = [
      ...filters,
      { ['pax/ist add ' + request.interessenten]: { le: { type: 'guid', value: 'pax/max' } } },
      "not zimmerkontingente/all(zk: zk/verfuegbarkeitsStatus eq 'NichtVerfuegbar')",
    ];
  }

  if (request.zimmerkontingentBeruecksichtigen) {
    filters = [
      ...filters,
      {
        zimmerkontingentePersonenFrei: { ge: request.interessenten },
      },
    ];
  }
  if (request.garantiertFilterSelected !== null) {
    const garantiertFilterValue = request.garantiertFilterSelected === 'Garantiert' ? true : false;

    if (garantiertFilterValue === true) {
      const garantiertFilterString = `(pax/ist add ${request.interessenten} ge pax/min or garantiert eq true)`;
      filters = [...filters, garantiertFilterString];
    } else {
      filters = [...filters, { garantiert: garantiertFilterValue }];
    }
  }
  // Wenn es kein Datum gibt, was die Reisetermin eingrenzt: Füge eins hinzu (von heute bis in einem Jahr)
  if (!request.filterModel.hasOwnProperty('reisefinderDate')) {
    const dateToday = new Date();
    filters = [...filters, { abreisedatum: { ge: dateToday } }];
  }
  return filters;
};

function getDate14DaysAgo() {
  const currentDate = new Date();
  const date14DaysAgo = subDays(currentDate, 14);
  return date14DaysAgo;
}
export default {
  state,
  actions,
  mutations,
  getters,
};
