import apiService from '@/core/common/services/api.service';
import buildQuery from 'odata-query';
import { add, subDays } from 'date-fns';
const moment = require('moment');

export const GET_AUFGABEN = 'aufgaben.getAufgaben';
export const INITIALLY_GET_AUFGABEN_NOTIFICATION_COUNT = 'aufgaben.initiallyGetAufgabenNotificationCount';
export const GET_AUFGABEN_NOTIFICATION_COUNT = 'aufgaben.getAufgabenNotificationCount';
export const SET_AUFGABEN_NOTIFICATION_COUNT = 'aufgaben.setAufgabenNotificationCount';
export const SET_AUFGABEN = 'aufgaben.setAufgaben';

export const EDIT_AUFGABE = 'aufgaben.editAufgabe';
export const LOAD_TRIGGERS = 'aufgaben.loadTriggers';
export const EDIT_STATUS = 'aufgaben.editStatus';
export const CREATE_AUFGABE = 'aufgaben.createAufgabe';

export const SET_IS_BUSY = 'aufgaben.setIsBusy';
export const SET_IS_AUFGABE_UPDATING = 'aufgaben.setIsAufgabeUpdating';
export const SET_TIME_UPDATED = 'aufgaben.setTimeUpdated';
export const SET_AUFGABE_DETAIL_DATA = 'aufgaben.setAufgabeDetailData';
export const REFETCH_SINGLE_AUFGABE = 'aufgaben.refetchSingleAufgabeAndUpdate';

export const DELETE_BEOBACHTER = 'aufgaben.deleteBeobachter';
export const ADD_BEOBACHTER = 'aufgaben.addBeobachter';

export const GET_TEAM_NOTIFICATION_COUNT = 'aufgaben.getTeamNotificationCount';
export const SET_TEAM_NOTIFICATION_COUNT = 'aufgaben.setTeamNotificationCount';

export const GET_FILTER_OPTIONS = 'aufgaben.getFilterOptions';
export const GET_FILTER_OPTIONS_ATTACHMENTS = 'aufgaben.getFilterOptionsAttachments';

// comments
export const SAVE_COMMENT = 'aufgaben.saveComment';
export const SAVE_REACTION = 'aufgaben.saveReaction';
export const DELETE_REACTION = 'aufgaben.deleteReaction';
export const DELETE_COMMENT = 'aufgaben.deleteComment';
export const SAVE_EDIT_COMMENT = 'aufgaben.saveEditComment';
export const SAVE_REPLY_COMMENT = 'aufgaben.saveReplyComment';
export const SET_IS_COMMENTS_BUSY = 'aufgaben.setIsCommentsBusy';

// comment setters
export const SET_EDIT_COMMENT_ID = 'aufgaben.setEditCommentId';
export const SET_REPLY_COMMENT_ID = 'aufgaben.setReCommentId';
export const SET_COMMENT_TEXT = 'aufgaben.setCommentText';
export const SET_MODE = 'aufgaben.setMode';

//filters
export const SET_FILTER_ANSICHT = 'aufgaben.setFilterAnsicht';

export const SET_AUFGABEN_COUNT = 'aufgaben.setAufgabenCount';

export const SET_COMMENTS_AUFGABEN_DETAIL = 'aufgaben.setCommentsAufgabenDetail';

const state = () => ({
  aufgaben: [],
  isBusy: false,
  isAufgabeUpdating: false,
  isCommentsBusy: false,
  filters: {
    ansicht: 'Mir zugewiesen', // options : 'Mir zugewiesen', 'Team'
  },
  aufgabeDetail: null,
  timeUpdated: null,
  count: null,
  notificationCount: 0,
  comments: {
    editCommentId: null,
    replyCommentId: null,
    commentText: '',
    mode: '',
  },
  teamNotificationCount: 0,
  reiseterminKuerzelFilterOptions: null,
});
const getters = {
  getCommentsFromSelectedAufgabe(state) {
    const answers = state.aufgabeDetail?.comments.filter(comment => comment.parentId !== null);
    const commentsWithAnswers = state.aufgabeDetail?.comments
      .filter(comment => comment.parentId === null)
      .map(comment => {
        const matchingAnswers = answers.filter(answer => answer.parentId === comment.id);
        if (matchingAnswers.length > 0) {
          return { ...comment, answers: matchingAnswers };
        } else return { ...comment, answers: [] };
      })
      .sort((a, b) => new Date(b.createdDateUtc) - new Date(a.createdDateUtc));
    return commentsWithAnswers;
  },
};
const actions = {
  [ADD_BEOBACHTER](context, params) {
    const { id, userId } = params;
    return apiService.post('/aufgaben/beobachter', { id, beobachter: userId }).then(async () => {
      const updatedAufgabe = await context.dispatch(REFETCH_SINGLE_AUFGABE, {
        aufgabenId: id,
      });
      context.state.aufgabeDetail = updatedAufgabe;
      context.state.aufgaben = context.state.aufgaben.map(aufgabe => {
        if (aufgabe.id === id) {
          return updatedAufgabe;
        } else return aufgabe;
      });
      return updatedAufgabe;
    });
  },
  [DELETE_BEOBACHTER](context, params) {
    const { id, userId } = params;
    return apiService.delete('/aufgaben/beobachter', { id, beobachter: userId }).then(async () => {
      const updatedAufgabe = await context.dispatch(REFETCH_SINGLE_AUFGABE, {
        aufgabenId: id,
      });
      context.state.aufgabeDetail = updatedAufgabe;
      context.state.aufgaben = context.state.aufgaben.map(aufgabe => {
        if (aufgabe.id === id) {
          return updatedAufgabe;
        } else return aufgabe;
      });
      return updatedAufgabe;
    });
  },
  [INITIALLY_GET_AUFGABEN_NOTIFICATION_COUNT](context) {
    if (!context.state.timeUpdated || moment().diff(moment(context.state.timeUpdated), 'minutes') > 1) {
      const currentUser = context.rootGetters['currentUserId'];
      context.commit(SET_TIME_UPDATED, moment());
      context.commit(SET_IS_BUSY, true);
      const count = true;
      const departmentOfLoggedInUser = context.rootGetters.getAllUsers?.find(
        user => user.id === currentUser
      ).department;
      // query anzahl von aufgaben, die noch offen sind und der eingeloggten Person zugewiesen sind
      const odataFilter = buildQuery({
        filter: {
          or: [{ bearbeiter: currentUser }, { team: departmentOfLoggedInUser ?? null }],
          status: 'Offen',
        },
        count,
        top: 0,
      });
      return apiService
        .get('/aufgaben' + odataFilter)
        .then(response => {
          context.commit(SET_AUFGABEN_NOTIFICATION_COUNT, response.data.result['@odata.count'] || 0);
          return response.data;
        })
        .finally(_ => context.commit(SET_IS_BUSY, false));
    }
  },
  [GET_AUFGABEN_NOTIFICATION_COUNT](context) {
    const currentUser = context.rootGetters['currentUserId'];
    context.commit(SET_TIME_UPDATED, moment());
    context.commit(SET_IS_BUSY, true);
    const count = true;
    const departmentOfLoggedInUser = context.rootGetters.getAllUsers.find(
      user => user.id === currentUser
    ).department;
    // query anzahl von aufgaben, die noch offen sind und der eingeloggten Person zugewiesen sind
    const odataFilter = buildQuery({
      filter: {
        or: [{ bearbeiter: currentUser }, { team: departmentOfLoggedInUser }],
        status: 'Offen',
      },
      count,
      top: 0,
    });
    return apiService
      .get('/aufgaben' + odataFilter)
      .then(response => {
        context.commit(SET_AUFGABEN_NOTIFICATION_COUNT, response.data.result['@odata.count'] || 0);
        return response.data;
      })
      .finally(_ => context.commit(SET_IS_BUSY, false));
  },
  [GET_TEAM_NOTIFICATION_COUNT](context) {
    const currentUser = context.rootGetters['currentUserId'];
    context.commit(SET_IS_BUSY, true);
    const count = true;
    const departmentOfLoggedInUser = context.rootGetters.getAllUsers.find(
      user => user.id === currentUser
    ).department;
    // query anzahl von aufgaben, die noch offen sind und der eingeloggten Person zugewiesen sind
    const odataFilter = buildQuery({
      filter: {
        team: departmentOfLoggedInUser,
        status: 'Offen',
      },
      count,
      top: 0,
    });
    return apiService
      .get('/aufgaben' + odataFilter)
      .then(response => {
        context.commit(SET_TEAM_NOTIFICATION_COUNT, response.data.result['@odata.count'] || 0);
        return response.data;
      })
      .finally(_ => context.commit(SET_IS_BUSY, false));
  },
  [GET_AUFGABEN](context, request) {
    const odataFilters = getOdataFilterFromRequest(request, context);
    context.commit(SET_IS_BUSY, true);
    return apiService
      .get('/aufgaben' + odataFilters)
      .then(response => {
        context.commit(SET_AUFGABEN, response?.data?.result?.value);
        context.commit(SET_AUFGABEN_COUNT, response.data.result['@odata.count'] || 0);
        return response.data;
      })
      .finally(_ => context.commit(SET_IS_BUSY, false));
  },
  [CREATE_AUFGABE](context, params) {
    context.commit(SET_IS_BUSY, true);
    return apiService
      .post('/aufgaben/', params)
      .then(() => {
        context.dispatch(GET_AUFGABEN_NOTIFICATION_COUNT);
        context.dispatch(GET_TEAM_NOTIFICATION_COUNT);
      })
      .finally(_ => context.commit(SET_IS_BUSY, false));
  },
  [EDIT_AUFGABE](context, params) {
    context.commit(SET_IS_AUFGABE_UPDATING, true);
    return apiService
      .put('/aufgaben/?id=' + params.id, params)
      .then(async () => {
        const updatedAufgabe = await context.dispatch(REFETCH_SINGLE_AUFGABE, {
          aufgabenId: context.state.aufgabeDetail.id,
        });
        context.state.aufgabeDetail = updatedAufgabe;
        context.state.aufgaben = context.state.aufgaben.map(aufgabe => {
          if (aufgabe.id === context.state.aufgabeDetail.id) {
            return updatedAufgabe;
          } else return aufgabe;
        });
        context.dispatch(GET_AUFGABEN_NOTIFICATION_COUNT);
        context.dispatch(GET_TEAM_NOTIFICATION_COUNT);
        return updatedAufgabe;
      })
      .finally(_ => context.commit(SET_IS_AUFGABE_UPDATING, false));
  },
  [LOAD_TRIGGERS](context, params) {
    return apiService.get('/aufgaben/statemachine/trigger?id=' + params.aufgabeId).then(response => {
      if (response.data.result?.trigger) {
        return response.data.result?.trigger;
      }
    });
  },
  [EDIT_STATUS](context, params) {
    context.commit(SET_IS_AUFGABE_UPDATING, true);
    return apiService
      .put('/aufgaben/statemachine/trigger?aufgabeId=' + params.aufgabeId, params)
      .then(async _ => {
        const updatedAufgabe = await context.dispatch(REFETCH_SINGLE_AUFGABE, {
          aufgabenId: context.state.aufgabeDetail.id,
        });
        context.dispatch(GET_AUFGABEN_NOTIFICATION_COUNT);
        context.dispatch(GET_TEAM_NOTIFICATION_COUNT);

        context.state.aufgabeDetail = updatedAufgabe;
        context.state.aufgaben = context.state.aufgaben.map(aufgabe => {
          if (aufgabe.id === context.state.aufgabeDetail.id) {
            return updatedAufgabe;
          } else return aufgabe;
        });
        return updatedAufgabe;
      })
      .finally(_ => context.commit(SET_IS_AUFGABE_UPDATING, false));
  },
  [SAVE_COMMENT](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);
    let { text } = params;
    return apiService
      .post('/Comments', {
        aufgabeId: context.state.aufgabeDetail.id,
        text,
      })
      .then(async () => {
        context.commit(SET_IS_COMMENTS_BUSY, false);
        // Optimistic update of local comment list
        const updatedAufgabe = await context.dispatch(REFETCH_SINGLE_AUFGABE, {
          aufgabenId: context.state.aufgabeDetail.id,
        });
        context.state.aufgabeDetail = updatedAufgabe;
        context.state.aufgaben = context.state.aufgaben.map(aufgabe => {
          if (aufgabe.id === context.state.aufgabeDetail.id) {
            return updatedAufgabe;
          } else return aufgabe;
        });
        return updatedAufgabe;
      })
      .catch(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [SAVE_REACTION](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);

    return apiService
      .post('/Comments', {
        aufgabeId: context.state.aufgabeDetail.id,
        text: params.text,
        parentId: params.replyCommentId,
      })
      .then(async () => {
        context.commit(SET_IS_COMMENTS_BUSY, false);
        context.commit(SET_MODE, '');
        context.commit(SET_COMMENT_TEXT, '');
        context.commit(SET_REPLY_COMMENT_ID, null);

        const updatedAufgabe = await context.dispatch(REFETCH_SINGLE_AUFGABE, {
          aufgabenId: context.state.aufgabeDetail.id,
        });
        context.state.aufgabeDetail = updatedAufgabe;
        context.state.aufgaben = context.state.aufgaben.map(aufgabe => {
          if (aufgabe.id === context.state.aufgabeDetail.id) {
            return updatedAufgabe;
          } else return aufgabe;
        });
        return updatedAufgabe;
      })
      .finally(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [DELETE_REACTION](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);

    return apiService
      .delete('/Comments?commentId=' + params.commentId, {
        CommentId: params.commentId,
      })
      .then(async () => {
        context.commit(SET_IS_COMMENTS_BUSY, false);
        context.commit(SET_MODE, '');
        context.commit(SET_COMMENT_TEXT, '');
        context.commit(SET_REPLY_COMMENT_ID, null);

        const updatedAufgabe = await context.dispatch(REFETCH_SINGLE_AUFGABE, {
          aufgabenId: context.state.aufgabeDetail.id,
        });
        context.state.aufgabeDetail = updatedAufgabe;
        context.state.aufgaben = context.state.aufgaben.map(aufgabe => {
          if (aufgabe.id === context.state.aufgabeDetail.id) {
            return updatedAufgabe;
          } else return aufgabe;
        });
        return updatedAufgabe;
      })
      .finally(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [DELETE_COMMENT](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);

    return apiService
      .delete('Comments?commentId=' + params.commentId, {
        CommentId: params.commentId,
      })
      .then(async () => {
        context.commit(SET_IS_COMMENTS_BUSY, false);
        // Optimistic update of local comment list
        const updatedAufgabe = await context.dispatch(REFETCH_SINGLE_AUFGABE, {
          aufgabenId: context.state.aufgabeDetail.id,
        });
        context.state.aufgabeDetail = updatedAufgabe;
        context.state.aufgaben = context.state.aufgaben.map(aufgabe => {
          if (aufgabe.id === context.state.aufgabeDetail.id) {
            return updatedAufgabe;
          } else return aufgabe;
        });
        return updatedAufgabe;
      })
      .finally(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [REFETCH_SINGLE_AUFGABE](context, params) {
    context.commit(SET_IS_AUFGABE_UPDATING, true);
    const odataQuery = buildQuery({
      expand: {
        comments: {},
        reisetermine: {
          expand: {
            ersatztermin: {},
            flugeinkauf: {},
            metadata: { expand: { ZiellandAbflughaefen: {}, ZiellandZielflughaefen: {}, Inlandsfluege: {} } },
            vorgangReleaseprozess: {},
            pax: {},
            comments: {},
            reise: { expand: { konfigurationen: {} } },
            gueltigeKonfiguration: {},
            zimmerkontingente: {},
            aufgaben: {},
            flugverfuegbarkeiten: {
              orderby: 'abfragedatum desc',
              select: [
                'id',
                'aufpreis',
                'status',
                'abfragedatum',
                'deutscherAbflughafenIataCode',
                'abflughafenIataCode',
                'zielflughafenIataCode',
                'zimmertyp',
                'verpflegung',
              ],
              filter: {
                abfragedatum: { ge: getDate14DaysAgo() },
              },
            },
          },
        },
        vorgaenge: {
          expand: {
            aufgaben: {},
            kunde: { expand: { master: {} } },
            hotelleistungen: {
              expand: {
                reisetermin: {
                  expand: {
                    reise: {
                      expand: { konfigurationen: {} },
                    },
                  },
                },
              },
            },
          },
        },
        reisen: { expand: { aufgaben: {}, konfigurationen: {} } },
        flugverfuegbarkeiten: {
          expand: {
            aufgaben: {},
            Reisetermin: {
              expand: {
                reise: {},
                pax: {},
                metadata: {
                  expand: { ZiellandZielflughaefen: {}, ZiellandAbflughaefen: {}, Inlandsfluege: {} },
                },
              },
            },
            abfragefehler: {},
          },
        },
      },
      filter: { id: params.aufgabenId },
    });

    return apiService
      .get('/aufgaben' + odataQuery)
      .then(response => {
        return response.data.result.value?.[0];
      })
      .finally(() => context.commit(SET_IS_AUFGABE_UPDATING, false));
  },
  [SAVE_EDIT_COMMENT](context, params) {
    context.commit(SET_IS_COMMENTS_BUSY, true);

    const { commentText } = params;

    if (context.state.comments.editCommentId) {
      return apiService
        .put('/Comments?commentId=' + context.state.comments.editCommentId, {
          text: commentText,
          commentId: context.state.comments.editCommentId,
        })
        .then(async () => {
          context.commit(SET_IS_COMMENTS_BUSY, false);
          // Optimistic update of local comment list
          const updatedAufgabe = await context.dispatch(REFETCH_SINGLE_AUFGABE, {
            aufgabenId: context.state.aufgabeDetail.id,
          });
          context.state.aufgabeDetail = updatedAufgabe;
          context.state.aufgaben = context.state.aufgaben.map(aufgabe => {
            if (aufgabe.id === context.state.aufgabeDetail.id) {
              return updatedAufgabe;
            } else return aufgabe;
          });
          context.commit(SET_MODE, '');
          context.commit(SET_COMMENT_TEXT, '');
          context.commit(SET_EDIT_COMMENT_ID, null);
          context.commit(SET_IS_COMMENTS_BUSY, false);

          return updatedAufgabe;
        })
        .catch(() => context.commit(SET_IS_COMMENTS_BUSY, false));
    }
  },
  [SAVE_REPLY_COMMENT](context) {
    context.commit(SET_IS_COMMENTS_BUSY, true);

    return apiService
      .post('/Comments', {
        aufgabeId: context.state.aufgabeDetail.id,
        text: context.state.comments.commentText,
        parentId: context.state.comments.replyCommentId,
      })
      .then(async () => {
        context.commit(SET_IS_COMMENTS_BUSY, false);
        context.commit(SET_MODE, '');
        context.commit(SET_COMMENT_TEXT, '');
        context.commit(SET_REPLY_COMMENT_ID, null);

        const updatedAufgabe = await context.dispatch(REFETCH_SINGLE_AUFGABE, {
          aufgabenId: context.state.aufgabeDetail.id,
        });
        context.state.aufgabeDetail = updatedAufgabe;
        context.state.aufgaben = context.state.aufgaben.map(aufgabe => {
          if (aufgabe.id === context.state.aufgabeDetail.id) {
            return updatedAufgabe;
          } else return aufgabe;
        });
        return updatedAufgabe;
      })
      .finally(() => context.commit(SET_IS_COMMENTS_BUSY, false));
  },
  [GET_FILTER_OPTIONS](context, request) {
    const odataFilters = getOdataFilterForCategories(request, context);
    return apiService
      .get('/aufgaben' + odataFilters)
      .then(response => {
        return response.data.result.value.map(k => k[request.attribute]);
      })
      .finally(_ => context.commit(SET_IS_BUSY, false));
  },
  [GET_FILTER_OPTIONS_ATTACHMENTS](context, request) {
    const filter = getFiltersFromContext(context, request);
    const expand = {
      reisetermine: {
        select: ['reiseterminkuerzel', 'id'],
      },
      reisen: {
        select: ['reisekuerzel', 'id'],
      },
      flugverfuegbarkeiten: {
        select: ['deutscherAbflughafenIataCode', 'id'],
        expand: {
          reisetermin: { select: ['reiseterminkuerzel'] },
        },
      },
      vorgaenge: { select: ['id'] },
    };
    const changedFilters = { filter, select: ['id'], expand };
    return apiService
      .get('/aufgaben' + buildQuery(changedFilters))
      .then(response => {
        return response.data.result.value;
      })
      .finally(_ => context.commit(SET_IS_BUSY, false));
  },
};
const mutations = {
  [SET_TEAM_NOTIFICATION_COUNT](state, notificationCount) {
    state.teamNotificationCount = notificationCount;
  },
  [SET_AUFGABEN](state, aufgaben) {
    state.aufgaben = aufgaben;
  },
  [SET_IS_BUSY](state, isBusy) {
    state.isBusy = isBusy;
  },
  [SET_IS_COMMENTS_BUSY](state, isBusy) {
    state.isCommentsBusy = isBusy;
  },
  [SET_IS_AUFGABE_UPDATING](state, isLoading) {
    state.isAufgabeUpdating = isLoading;
  },
  [SET_FILTER_ANSICHT](state, ansicht) {
    state.filters.ansicht = ansicht;
  },
  [SET_AUFGABE_DETAIL_DATA](state, data) {
    state.aufgabeDetail = data;
  },
  [SET_TIME_UPDATED](state, time) {
    state.timeUpdated = time;
  },
  [SET_AUFGABEN_NOTIFICATION_COUNT](state, notificationCount) {
    state.notificationCount = notificationCount;
  },
  [SET_EDIT_COMMENT_ID](state, editCommentId) {
    state.comments.editCommentId = editCommentId;
  },
  [SET_REPLY_COMMENT_ID](state, replyCommentId) {
    state.comments.replyCommentId = replyCommentId;
  },
  [SET_COMMENT_TEXT](state, commentText) {
    state.comments.commentText = commentText;
  },
  [SET_MODE](state, mode) {
    state.comments.mode = mode;
  },
  [SET_AUFGABEN_COUNT](state, count) {
    state.count = count;
  },
  [SET_COMMENTS_AUFGABEN_DETAIL](state, comments) {
    state.aufgabeDetail.comments = comments;
  },
};

const getOdataFilterFromRequest = (request, context) => {
  // startRow => skip
  // endRow-startRow => top
  if (request) {
    const { startRow, endRow, sortModel } = request;
    const top = endRow - startRow;
    const skip = startRow;
    const orderBy =
      sortModel.length > 0
        ? sortModel.map(sortObject => sortObject.colId + ' ' + sortObject.sort).join(',')
        : null;
    const filter = getFiltersFromContext(context, request);
    return buildQuery({
      top,
      skip,
      orderBy,
      filter,
      count: true,
      expand: {
        reisetermine: {
          select: ['reiseterminkuerzel'],
        },
        vorgaenge: {
          select: ['id'],
        },
        reisen: { select: ['reisekuerzel'] },
        flugverfuegbarkeiten: {
          select: ['id', 'deutscherAbflughafenIataCode'],
          expand: {
            reisetermin: { select: ['reiseterminkuerzel'] },
          },
        },
      },
    });
  }
  return '';
};

const getOdataFilterForCategories = (request, context) => {
  if (request) {
    const filter = getFiltersFromContext(context, request);
    return buildQuery({
      filter,
      select: [request.attribute],
    });
  }
  return '';
};

const getFiltersFromContext = (context, request) => {
  const currentUserId = context.rootGetters['currentUserId'];
  const filterObject = context.state.filters;
  const { filterModel, fakeUser } = request;
  const filterModelLength = Object.keys(filterModel).length;
  let filters = {};

  if (fakeUser !== null) {
    if (filterObject.ansicht === 'Mir zugewiesen') {
      filters = { ...filters, bearbeiter: fakeUser };
    } else if (filterObject.ansicht === 'Von mir erstellt') {
      filters = { ...filters, autor: fakeUser };
    } else if (filterObject.ansicht === 'Team zugewiesen') {
      const departmentOfLoggedInUser = context.rootGetters.getAllUsers.find(
        user => user.id === fakeUser
      ).department;

      if (departmentOfLoggedInUser) {
        const membersOfTeam = context.rootGetters.getAllUsers
          .filter(user => user.department === departmentOfLoggedInUser)
          .map(user => user.id);
        filters = { ...filters, bearbeiter: { in: membersOfTeam } };
      }
    } else if (filterObject.ansicht === 'Nicht zugewiesen') {
      const departmentOfLoggedInUser = context.rootGetters.getAllUsers.find(
        user => user.id === fakeUser
      ).department;

      filters = { ...filters, team: departmentOfLoggedInUser };
    } else if (filterObject.ansicht === 'Von mir beobachtet') {
      filters = { ...filters, a: ["beobachter/any(d:contains(d,'" + fakeUser + "'))"] };
    } else if (filterObject.ansicht === 'Vom Team erstellt') {
      const departmentOfLoggedInUser = context.rootGetters.getAllUsers.find(
        user => user.id === fakeUser
      ).department;
      if (departmentOfLoggedInUser) {
        const membersOfTeam = context.rootGetters.getAllUsers
          .filter(user => user.department === departmentOfLoggedInUser)
          .map(user => user.id);
        filters = { ...filters, autor: { in: membersOfTeam } };
      }
    }
  } else {
    if (filterObject.ansicht === 'Mir zugewiesen') {
      filters = { ...filters, bearbeiter: currentUserId };
    } else if (filterObject.ansicht === 'Von mir erstellt') {
      filters = { ...filters, autor: currentUserId };
    } else if (filterObject.ansicht === 'Team zugewiesen') {
      const departmentOfLoggedInUser = context.rootGetters.getAllUsers.find(
        user => user.id === currentUserId
      ).department;

      if (departmentOfLoggedInUser) {
        const membersOfTeam = context.rootGetters.getAllUsers
          .filter(user => user.department === departmentOfLoggedInUser)
          .map(user => user.id);
        filters = { ...filters, bearbeiter: { in: membersOfTeam } };
      }
    } else if (filterObject.ansicht === 'Nicht zugewiesen') {
      const departmentOfLoggedInUser = context.rootGetters.getAllUsers.find(
        user => user.id === currentUserId
      ).department;

      filters = { ...filters, team: departmentOfLoggedInUser };
    } else if (filterObject.ansicht === 'Von mir beobachtet') {
      filters = { ...filters, a: ["beobachter/any(d:contains(d,'" + currentUserId + "'))"] };
    } else if (filterObject.ansicht === 'Vom Team erstellt') {
      const departmentOfLoggedInUser = context.rootGetters.getAllUsers.find(
        user => user.id === currentUserId
      ).department;
      if (departmentOfLoggedInUser) {
        const membersOfTeam = context.rootGetters.getAllUsers
          .filter(user => user.department === departmentOfLoggedInUser)
          .map(user => user.id);
        filters = { ...filters, autor: { in: membersOfTeam } };
      }
    }
  }

  if (filterModelLength > 0) {
    Object.keys(filterModel).forEach(filterKey => {
      const filterValues = filterModel[filterKey];
      if (filterValues.filterType === 'text') {
        if (filterKey === 'id') {
          if (!isNaN(filterValues.filter)) {
            filters = { ...filters, [filterKey]: parseInt(filterValues.filter) };
          }
        } else if (filterKey === 'fullTextSearch') {
          const searchText = filterValues.filter;
          const shortReiseterminFormatted =
            searchText.length === 14
              ? searchText.substring(0, 6) +
                '_' +
                searchText.substring(6, 10) +
                '-' +
                searchText.substring(10, 12) +
                '-' +
                searchText.substring(12, 14)
              : null;
          const filterReisetermin = shortReiseterminFormatted
            ? {
                or: [
                  { reiseterminkuerzel: { contains: searchText } },
                  { reiseterminkuerzel: { contains: shortReiseterminFormatted } },
                ],
              }
            : { reiseterminkuerzel: { contains: searchText } };
          let fullTextFilters = {
            or: [
              { beschreibung: { contains: searchText } },
              {
                comments: {
                  any: {
                    text: { contains: searchText },
                  },
                },
              },
              {
                reiseTermine: {
                  any: filterReisetermin,
                },
              },
              {
                reisen: {
                  any: {
                    reisekuerzel: { contains: searchText },
                  },
                },
              },
              {
                flugverfuegbarkeiten: {
                  any: {
                    reisetermin: filterReisetermin,
                  },
                },
              },
            ],
          };
          if (!isNaN(searchText)) {
            fullTextFilters['or'] = [
              ...fullTextFilters['or'],
              {
                vorgaenge: {
                  any: {
                    id: +searchText,
                  },
                },
              },
            ];
          }
          filters = { ...filters, ...fullTextFilters };
        } else {
          filters = { ...filters, [filterKey]: { [filterValues.type]: filterValues.filter } };
        }
      } else if (filterValues.filterType === 'date') {
        if (filterKey === 'createdDateUtc') {
          const dateFrom = new Date(filterValues.dateFrom.substring(0, 10));
          const dateTo = add(new Date(filterValues.dateTo.substring(0, 10)), { hours: 23, minutes: 59 });

          filters = { ...filters, [filterKey]: { ge: dateFrom, le: dateTo } };
        } else {
          const dateFrom = new Date(filterValues.dateFrom.substring(0, 10));
          const dateTo = new Date(filterValues.dateTo.substring(0, 10));

          filters = { ...filters, [filterKey]: { ge: dateFrom, le: dateTo } };
        }
      } else if (filterValues.filterType === 'set') {
        if (filterKey === 'attachmentAufgabe') {
          const { attachmentFilterData } = request;
          const filter = {};
          filterValues.values.forEach(item => {
            // Vorgang
            if (!isNaN(parseInt(item))) {
              if ('vorgaenge' in filter) {
                filter['vorgaenge'] = [...filter['vorgaenge'], +item];
              } else filter['vorgaenge'] = [+item];
            }
            if (item.length === 6) {
              if ('reisen' in filter) {
                filter['reisen'] = [...filter['reisen'], item];
              } else filter['reisen'] = [item];
            }
            if (item.length === 17) {
              if ('reisetermine' in filter) {
                filter['reisetermine'] = [...filter['reisetermine'], item];
              } else filter['reisetermine'] = [item];
            }
            if (item.length === 21) {
              if ('flugverfuegbarkeiten' in filter) {
                filter['flugverfuegbarkeiten'] = [
                  ...filter['flugverfuegbarkeiten'],
                  +attachmentFilterData[item],
                ];
              } else {
                filter['flugverfuegbarkeiten'] = [+attachmentFilterData[item]];
              }
            }
          });
          const odataFilter = [];
          for (const [key, value] of Object.entries(filter)) {
            console.log(key, value);
            if (key === 'flugverfuegbarkeiten') {
              odataFilter.push({
                flugverfuegbarkeiten: {
                  any: {
                    id: { in: value },
                  },
                },
              });
            }
            if (key === 'reisetermine') {
              odataFilter.push({
                reisetermine: {
                  any: {
                    reiseterminkuerzel: { in: value },
                  },
                },
              });
            }
            if (key === 'reisen') {
              odataFilter.push({
                reisen: {
                  any: {
                    reisekuerzel: { in: value },
                  },
                },
              });
            }
            if (key === 'vorgaenge') {
              odataFilter.push({
                vorgaenge: {
                  any: {
                    id: { in: value },
                  },
                },
              });
            }
          }
          console.log(odataFilter);
          filters = { ...filters, or: odataFilter };
        } else {
          filters = { ...filters, [filterKey]: { in: filterValues.values } };
        }
      }
    });
  }

  // filter out closed aufgaben if filter not present
  if (
    !Object.keys(filterModel).includes('status') &&
    !Object.keys(filterModel).includes('fullTextSearch') &&
    !Object.keys(filterModel).includes('id')
  ) {
    if (request.pendingCheckbox) {
      filters = { ...filters, status: { in: ['Offen', 'InArbeit', 'Pending'] } };
    } else {
      filters = { ...filters, status: { in: ['Offen', 'InArbeit', 'Erledigt'] } };
    }
  }

  // if id filter is present, reset bearbeiter
  if (Object.keys(filterModel).includes('id')) {
    delete filters['bearbeiter'];
  }
  return filters;
};
function getDate14DaysAgo() {
  const currentDate = new Date();
  const date14DaysAgo = subDays(currentDate, 14);
  return date14DaysAgo;
}
export default {
  state,
  actions,
  getters,
  mutations,
};
